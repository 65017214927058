<template>
  <div class="admin-user">
    <a href="" class="btn btn-primary btn-sm float-right">角色</a>
    <a href="" class="btn btn-primary btn-sm float-right mr-2">权限</a>
    <h5>用户管理</h5>

    <div class="mt-3">
      <div class="filter">
        <form action="" class="form-inline" @submit="onFilterSubmit">
          <div class="form-row align-items-center">
            <div class="col-auto">
              <input type="date" class="form-control form-control-sm" v-model="keywords.start">
            </div>
            <div class="col-auto">
              到
            </div>
            <div class="col-auto">
              <input type="date" class="form-control form-control-sm" v-model="keywords.end">
            </div>
            <div class="col-auto">
              <select name="" class="form-control form-control-sm"  v-model="keywords.role">
                <option value="-1" disabled selected>请选择角色</option>
                <option value="1">管理员</option>
                <option value="2">渠道</option>
                <option value="2">财务</option>
                <option value="2">用户</option>
              </select>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary btn-sm">查询</button>
            </div>
          </div>
        </form>
      </div>
      <div class="mt-3">
        <b-table hover striped head-variant="light" :items="users" />
      </div>
    </div>
  </div>
</template>
<script>
import User from "@/model/User";

export default {
  data() {
    return {
      users: [],
      keywords: {
        start: '',
        end: '',
        role: -1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      User.all().then(response => {
        if (response.data.code === 0) {
          this.users = response.data.data
        }
      })
    },
    onFilterSubmit() {

    }
  }
}
</script>